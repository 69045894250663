.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.point{
  cursor: pointer !important;
}
.AppBarLight{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-image: linear-gradient(180deg, #44ad67 10% , #468f59 50%, #E8E8E8) !important;
  box-shadow: none !important;
}
.AppBarDark{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-image: linear-gradient(180deg, #44ad67 10% , #468f59 50%, #333333) !important;
  box-shadow: none !important;
}

.selectedborder{
  border-style:solid;
  border-color: #44ad67 !important;
  border-left-width: 6px !important;
}

.border-top{
  border-top-width: 2px !important;
}

.text-dark-secondary{
  color: #bbbbbb !important;
}

a, a:hover{
  color: #000;
  text-decoration: none;
}
.darkfull > .MuiDialog-container > .MuiPaper-root{
  background-color: #011345;
}
.hover:hover{
  color: #fff;
}
